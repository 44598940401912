import { DBFactory } from "~/classes/database/db_factory";
import { Assignment } from "../assignments/assignment.model";
import { EducationResource } from "../education-resources/education-resource.model";

export type AssignmentTemplates = AssignmentTemplate[];

export class AssignmentTemplate extends Assignment {
  override get databaseConfig() {
    return {
      collection: `/classroomTemplates/${this.classroomId}/assignments`,
      path: `/classroomTemplates/${this.classroomId}/assignments/${this.id}`,
    };
  }

  static override fromMap(data: any): AssignmentTemplate {
    if (data.resources != undefined) {
      data.resources = data.resources.map((resource: any) => {
        return EducationResource.fromMap(resource);
      });
    }

    return new AssignmentTemplate(data);
  }

  static fromAssignment({
    assignment,
    classroomTemplateId,
  }: {
    assignment: Assignment;
    classroomTemplateId: string;
  }): AssignmentTemplate {
    const db = DBFactory.createDatabase();

    const map = assignment.toMap() as any;
    map.id = db.generateId({
      path: `/classroomTemplates/${classroomTemplateId}/assignments`,
      collection: `/classroomTemplates/${classroomTemplateId}/assignments`,
    });
    map.classroomId = classroomTemplateId;
    map.numGradedDocuments = 0;
    map.numGradedFinalDraftDocuments = 0;

    return AssignmentTemplate.fromMap(map);
  }

  toAssignment(classroomId: string): Assignment {
    const db = DBFactory.createDatabase();
    const map = this.toMap() as any;
    map.id = db.generateId({
      path: `/classrooms/${classroomId}/assignments`,
      collection: `/classrooms/${classroomId}/assignments`,
    });
    map.classroomId = classroomId;
    map.openTimestamp = undefined;
    map.closeTimestamp = undefined;
    map.dueTimestamp = undefined;

    if (map.resources != undefined) {
      map.resources = map.resources.map((resource: any) => {
        return EducationResource.fromMap(resource);
      });
    }

    return Assignment.fromMap(map);
  }

  override async save() {
    const db = DBFactory.createDatabase();

    console.log("this.toMap()", this.toMap());
    console.log("this.databaseConfig", this.databaseConfig);

    this.id = await db.save(this.toMap(), this.databaseConfig);
  }
}
