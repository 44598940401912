import { DBFactory } from "~/classes/database/db_factory";
import { Classroom } from "../classrooms/classroom.model";
import { EducationResource } from "../education-resources/education-resource.model";
import { ClassroomTemplatesService } from "./classroom-templates.service";

export type ClassroomTemplates = ClassroomTemplate[];

export class ClassroomTemplate extends Classroom {
  override get databaseConfig() {
    return {
      path: `classroomTemplates/${this.id}`,
      collection: "classroomTemplates",
    };
  }

  static override fromMap(data: any): ClassroomTemplate {
    if (data["resources"]) {
      data["resources"] = data["resources"].map((resource: any) => {
        return EducationResource.fromMap(resource);
      });
    }

    return new ClassroomTemplate(data);
  }

  static override default(userId: string) {
    return new ClassroomTemplate({
      name: "",
      label: "",
      externalId: "",
      externalProvider: "iew",
      externalProviderUserId: userId,
      userId: userId,
      shouldHide: false,
      isArchived: false,
      classroomAssignmentGroups: [],
      enableStudentReview: false,
      enableClassSessions: false,
      allowKWOSubmission: false,
      allowRoughDraftSubmission: false,
      resources: [],
    });
  }

  static fromClassroom(classroom: Classroom): ClassroomTemplate {
    const map = classroom.toMap() as any;
    delete map.id;
    map.numGradedDocuments = 0;
    return ClassroomTemplate.fromMap(map);
  }

  toClassroom(): Classroom {
    const db = DBFactory.createDatabase();

    const map = this.toMap() as any;
    map.id = db.generateId({
      collection: "classrooms",
      path: "classrooms",
    });

    return Classroom.fromMap(map);
  }

  override toMap(): ModelDatabaseData {
    const map = super.toMap();
    map.name = map.label;
    return map;
  }

  override async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    const db = DBFactory.createDatabase();

    if (this.id == undefined) {
      return;
    }

    let models: BaseModel[] = [this];

    const assignments =
      await ClassroomTemplatesService.fetchClassroomTemplateAssignments({
        userId: this.userId,
        organizationId: this.organizationId,
        classroomTemplateId: this.id,
      });

    models = [...models, ...assignments];

    const classSessions =
      await ClassroomTemplatesService.fetchClassroomClassSessionTemplates({
        userId: this.userId,
        organizationId: this.organizationId,
        classroomTemplateId: this.id,
      });

    models = [...models, ...classSessions];

    await db.batchDelete(models);
  }
}
