import { DBFactory } from "~/classes/database/db_factory";
import { AssignmentResource } from "../assignments/assignment-resource.model";
import { EducationResource } from "../education-resources/education-resource.model";

export type AssignmentResourceTemplates = AssignmentResourceTemplate[];

export class AssignmentResourceTemplate extends AssignmentResource {
  override get databaseConfig() {
    return {
      collection: `/classroomTemplates/${this.classroomId}/assignments/${this.assignmentId}/resources`,
      path: `/classroomTemplates/${this.classroomId}/assignments/${this.assignmentId}/resources/${this.id}`,
    };
  }

  static override fromMap(map: any): AssignmentResourceTemplate {
    map.resource = EducationResource.fromMap(map.resource);
    return new AssignmentResourceTemplate(map);
  }

  static override default(
    userId: string,
    classroomId: string,
    assignmentId: string,
    name: string,
    educationResource: EducationResource
  ) {
    return new AssignmentResourceTemplate({
      classroomId: classroomId,
      assignmentId: assignmentId,
      userId: userId,
      name: name,
      resource: educationResource,
      createdAtTimestamp: new Date().getTime(),
    });
  }

  static fromAssignmentResource({
    assignmentResource,
    classroomTemplateId,
    assignmentTemplateId,
  }: {
    assignmentResource: AssignmentResource;
    classroomTemplateId: string;
    assignmentTemplateId: string;
  }): AssignmentResourceTemplate {
    const map = assignmentResource.toMap() as any;
    delete map.id;
    map.classroomId = classroomTemplateId;
    map.assignmentId = assignmentTemplateId;

    return AssignmentResourceTemplate.fromMap(map);
  }

  toAssignmentResource({
    classroomId,
    assignmentId,
  }: {
    classroomId: string;
    assignmentId: string;
  }): AssignmentResource {
    const map = this.toMap() as any;
    delete map.id;
    map.classroomId = classroomId;
    map.assignmentId = assignmentId;

    return AssignmentResource.fromMap(map);
  }

  override async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }

  override async delete() {
    const db = DBFactory.createDatabase();
    await this.resource.delete();
    await db.delete(this.databaseConfig);

    console.log("deleted assignment resource template", this.databaseConfig);
  }
}
